<template>
  <div>
    <el-dialog width="900px" title="新增材料通知单" :visible.sync="cailiaotongzhidanDlg_isShow" :close-on-click-modal="false">
      <CailiaotongzhidanForm
        :cailiaotongzhidan_id_toadd="cailiaotongzhidan_id_toadd"
        ref="cailiaotongzhidanForm"
        @submitForm="submitCailiaotongzhidanForm"
        @resetForm="resetForm"
      ></CailiaotongzhidanForm>
    </el-dialog>
    <el-dialog
      width="900px"
      title="修改材料通知单"
      :visible.sync="cailiaotongzhidanEditDlg_isShow"
      v-if="cailiaotongzhidanEditDlg_isShow"
      :close-on-click-modal="false"
    >
      <CailiaotongzhidaneditForm
        ref="cailiaotongzhidaneditForm"
        :formData="cailiaotongzhidaneditForm"
        @submitForm="submitCailiaotongzhidaneditForm"
        @resetForm="reseteditForm"
      ></CailiaotongzhidaneditForm>
    </el-dialog>

    <el-dialog width="1800px" title="材料通知单修改记录" :visible.sync="cailiaotongzhidanEditRecordDlg_isShow" :close-on-click-modal="false">
      <ViewtzdeditTable :tableData="cailiaotongzhidanEditViewTableData"></ViewtzdeditTable>
    </el-dialog>

    <el-card shadow="never">
      <div slot="header">
        <span style="font-weight:bold">{{ is_jieban === 1 ? '借板合同明细' : '合同明细' }}</span>
      </div>
      <div>
        <HeyuedetailTable :contractDetail="contractDetail"></HeyuedetailTable>
      </div>
    </el-card>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight:bold">{{ is_jieban === 1 ? '借板材料通知单' : '材料通知单' }}</span>
        <el-button
          type="primary"
          size="small"
          style="float: right;margin-right:100px"
          @click="addCailiaotongzhidan"
          v-if="usertype.code === '2' || usertype.code === '4'"
          >新 增</el-button
        >
      </div>

      <cailiaotongzhidan-table
        :tableData="cailiaotongzhidanTableData"
        :config="config"
        :userType="usertype"
        :isShowHeyueField="false"
        @changePage="getCailiaotongzhidanByID"
        @zuofei="zuofeiCailiaotongzhidan"
        @del="deleteCailiaotongzhidan"
        @edit="ApplyEditCailiaotongzhidan"
        @wuziAudit="wuziAuditCailiaotongzhidan"
        @caiwuAudit="caiwuAuditCailiaotongzhidan"
        @kuguanConfirm="confirmCailiaotongzhidan"
        @ConvertToFormalTongzhidan="ConvertToFormalTongzhidan"
        @viewEditRecord="viewEditRecord"
      ></cailiaotongzhidan-table>
    </el-card>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span style="font-weight:bold">出板情况</span>
      </div>
      <chubanqingkuang-table :tableData="chubanqingkuangTableData" :config="config" :userType="usertype"></chubanqingkuang-table>
    </el-card>
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import HeyuedetailTable from './HeyueDetailTable'
import CailiaotongzhidanTable from '../cailiaoTongzhidan/CailiaotongzhidanTable'
import ChubanqingkuangTable from '../cailiaoTongzhidan/ChubanqingkuangTable'
import CailiaotongzhidanForm from '../cailiaoTongzhidan/CailiaotongzhidanForm'
import CailiaotongzhidaneditForm from '../cailiaoTongzhidan/CailiaotongzhidaneditForm'
import ViewtzdeditTable from '../cailiaoTongzhidan/ViewtzdeditTable'

export default {
  components: {
    HeyuedetailTable,
    CailiaotongzhidanTable,
    CailiaotongzhidanForm,
    CailiaotongzhidaneditForm,
    ChubanqingkuangTable,
    ViewtzdeditTable
  },
  props: {
    data: Object
  },

  data() {
    return {
      usertype: '',
      currentContract: '',
      xiangmuorgongdi: '',
      heyue_added: '',
      cailiaotongzhidanDlg_isShow: false,
      cailiaotongzhidanEditDlg_isShow: false,
      cailiaotongzhidanEditRecordDlg_isShow: false,
      cailiaotongzhidan_operateType: 'add',
      contractDetail: {},
      cailiaotongzhidanForm: {},
      cailiaotongzhidaneditForm: {},
      cailiaotongzhidan_id_toadd: '',

      // chukuForm: {},
      // cailiaotongzhidans: [],
      cailiaotongzhidanTableData: [],
      cailiaotongzhidanEditViewTableData: [],
      chubanqingkuangTableData: [],
      is_jieban: 0,

      config: {
        page: 1,
        total: 30,
        loading: false
      }
    }
  },

  methods: {
    //获取合同详情
    getContractDetail() {
      this.currentContract = this.$route.query.id
      console.log('is_jieban_1..........:', this.$route.query.is_jieban)
      if (this.$route.query.is_jieban != null) {
        this.is_jieban = 1
      }
      console.log('contract_id........:', this.currentContract)
      console.log('is_jieban..........:', this.is_jieban)
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getContractDetail/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('res.data', res.data)
          this.contractDetail = res.data
          if (this.usertype.contract_detail_amount === false) {
            this.contractDetail.contract_amount = '******'
          }
          if (this.usertype.contract_detail_real_amount === false) {
            this.contractDetail.real_amount = '******'
          }

          console.log('this.contractDetail:', this.contractDetail)
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //获取当前合约信息
    getHeyueInfo() {
      this.currentContract = this.$route.query.id
      // this.xiangmuorgongdi = this.$route.query.xiangmuorgongdi
      // this.heyue_added = this.$route.query.heyue_added
    },

    //获取材料通知单信息
    getCailiaotongzhidanByID() {
      // let tongzhidans = []
      console.log('cailiao tongzhidan by id')
      this.currentContract = this.$route.query.id
      let contract_id = this.$route.query.contract_id
      const api = '/getCailiaotongzhidanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            //取得当前合同下编号最大的一个材料通知单编号，以确定下一个待增加的通知单编号
            let count = res.data.data.length
            let last_rec = res.data.data[count - 1].code
            let pos = last_rec.indexOf('#-')
            if (pos === -1) {
              pos = last_rec.indexOf(')-')
              if (pos === -1) {
                pos = last_rec.indexOf('）-')
                if (pos === -1) {
                  pos = last_rec.indexOf('JIEBAN-')
                  pos = pos + 5
                }
              }
            }
            let num = last_rec.slice(pos + 2)
            num = Number(num)
            num = num + 1
            if (num < 10) {
              num = '0' + num
            } else {
              num = String(num)
            }

            this.cailiaotongzhidan_id_toadd = contract_id + '-' + num
            this.cailiaotongzhidanTableData = res.data.data.map(item => {
              item.chuban_hj = Number(item.zujin_hj) + Number(item.yajin_hj) + Number(item.yunfei_hj) + Number(item.diaozhuangfei_hj)
              item.chuban_hj = item.chuban_hj.toFixed(2)

              // this.cailiaotongzhidans.push(item.code)

              return item
            })

            // this.chukuForm.cailiaotongzhidans = tongzhidans
            console.log('getCailiaotongzhidanByID', this.cailiaotongzhidanTableData)
            console.log('材料通知单数量', this.cailiaotongzhidanTableData.length)
            // console.log('材料通知单编号', this.cailiaotongzhidans)

            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计

            //按材料、材料规格排序
            newArray = newArray.concat(this.cailiaotongzhidanTableData)
            console.log('newArray----------------:', newArray)

            //1. 排序
            this.cailiaotongzhidanTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序
              if (a.cailiao === b.cailiao) {
                return a.cailiaoguige < b.cailiaoguige ? -1 : 1
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })

            console.log('this.cailiaotongzhidanTableData', this.cailiaotongzhidanTableData)
            //2. 计算小计和总计
            let array = this.cailiaotongzhidanTableData
            // let newArray = [array[0]]

            let prior = array[0]
            let xiaoji = parseInt(prior.in_amount) + parseInt(prior.add_amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.in_amount) + parseInt(curr.add_amount)
              if (curr.cailiao_name === prior.cailiao_name && curr.cailiaoguige_name === prior.cailiaoguige_name) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle = prior.cailiao_name + '-' + prior.cailiaoguige_name + ' ' + '小计'
                let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.in_amount) + parseInt(curr.add_amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle = curr.cailiao_name + '-' + curr.cailiaoguige_name + ' ' + '小计'
                  let temp = { cailiao_name: xiaojiTitle, in_amount: xiaoji, type: '2' }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, in_amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.cailiaotongzhidanTableData = newArray
            console.log('材料通知单数量（处理后）', this.cailiaotongzhidanTableData.length)
          } else {
            this.cailiaotongzhidanTableData = []
            this.cailiaotongzhidan_id_toadd = contract_id + '-01'
            console.log('材料通知单合约信息为空！')
          }
        })
    },
    //增加材料通知单
    addCailiaotongzhidan() {
      this.cailiaotongzhidanForm = {}
      this.cailiaotongzhidan_operateType = 'add'
      this.cailiaotongzhidanDlg_isShow = true
    },
    //增加材料通知单表单提交
    submitCailiaotongzhidanForm(formData) {
      this.cailiaotongzhidanForm = formData
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name

      this.cailiaotongzhidanForm.contract = this.currentContract
      this.cailiaotongzhidanForm.added_person = this.user_realname
      this.cailiaotongzhidanForm.is_jieban = this.is_jieban
      this.cailiaotongzhidanForm.code = this.cailiaotongzhidan_id_toadd
      // this.chukuForm.added_person_time = utils.getDateTime()
      console.log('cailiaotongzhidanForm:', this.cailiaotongzhidanForm)
      this.$axios.post('/addCailiaotongzhidan/', this.cailiaotongzhidanForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === 1000) {
          this.$message({
            type: 'success',
            message: '增加材料通知单成功!',
            duration: 2000
          })
          this.cailiaotongzhidanDlg_isShow = false
          this.getCailiaotongzhidanByID()
        } else {
          this.$message({
            type: 'warning',
            message: '添加失败',
            duration: 4000
          })
          console.log(res.data.msg)
        }
      })
    },
    resetForm() {
      this.cailiaotongzhidanDlg_isShow = false
      this.cailiaotongzhidanForm = {}
    },
    //修改材料通知单
    editCailiaotongzhidan(row) {
      this.cailiaotongzhidaneditForm = row
      console.log('this.cailiaotongzhidaneditForm', this.cailiaotongzhidaneditForm)
      this.cailiaotongzhidanEditDlg_isShow = true
    },
    //修改材料通知单表单提交
    submitCailiaotongzhidaneditForm(formData) {
      this.cailiaotongzhidaneditForm = formData
      console.log('cailiaotongzhidaneditForm:', this.cailiaotongzhidaneditForm)
      this.$axios.put('/editCailiaotongzhidan/', this.cailiaotongzhidaneditForm).then(res => {
        console.log('response:', res.data)
        if (res.data.code === '1000') {
          this.$message({
            type: 'success',
            message: '修改材料通知单成功!',
            duration: 2000
          })
          this.cailiaotongzhidanEditDlg_isShow = false
          this.getCailiaotongzhidanByID()
        } else {
          this.$message({
            type: 'warning',
            message: '修改失败！',
            duration: 4000
          })
          console.log(res.data.msg)
        }
      })
    },
    reseteditForm() {
      this.cailiaotongzhidanEditDlg_isShow = false
      this.cailiaotongzhidaneditForm = {}
    },
    //删除材料通知单信息
    deleteCailiaotongzhidan(row) {
      this.$confirm('此操作将永久删除此材料通知单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteCailiaotongzhidan/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功了!'
              })
              this.getCailiaotongzhidanByID()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    /*
    物资审核
    */
    wuziAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过物资审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/wuziAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '物资审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消物资审核'
          })
        })
    },

    /*
    财务审核
    */
    caiwuAuditCailiaotongzhidan(row) {
      this.$confirm('确认通过财务审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/caiwuAuditCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '财务审核成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消财务审核'
          })
        })
    },
    /*
    库管确认
    */
    confirmCailiaotongzhidan(row) {
      this.$confirm('确认通过确认吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // let operateTime = utils.getDateTime()
          // console.log(operateTime)
          let params = {
            id: row.id
          }
          this.$axios.put('/confirmCailiaotongzhidan/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '确认成功!'
            })
            this.getCailiaotongzhidanByID()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消确认'
          })
        })
    },
    //获取出板情况
    getChubanqingkuangByID() {
      console.log('cailiao tongzhidan by id')
      const api = '/getChubanqingkuangByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('出版情况：', res)
          if (res.data.code === '1000') {
            this.chubanqingkuangTableData = res.data.data
          }
        })
    },
    /**
     * 申请转为正式材料通知单
     * 借板通知单is_jieban和status按钮的状态变化：
     * 初始状态:is_jieban===true, status='NORMAL' ，按钮文本：'申请转为正式' ，分公司经理可操作（其他用户disabled）
     * 1. 分公司经理申请转为正式材料通知单：status='APPLY'，按钮文本：'等待物资审核'，物资可操作（其他用户disabled）
     * 2. 物资审核：status='WUZISHENHE'，按钮文本：‘等待财务审核'，财务可操作（其他用户disabled）
     * 3. 财务审核：status='CAIWUSHENHE',按钮文本：'确认转为正式'，分公司经理可操作（其他用户disabled）
     * 4. 分公司经理确认转为正式材料通知单：点击按钮后提示”该借板材料通知单将删除，请到正式材料通知单中查看相应的材料通知单“，is_jieban===false,status=‘FORMAL'
     */
    ConvertToFormalTongzhidan(row) {
      let params = {
        id: row.id,
        contract: row.contract,
        contract_id: row.contract_id,
        op: ''
      }
      let msg = '确定通过审核吗？'
      console.log('当前借板材料通知单:', row)
      if (this.usertype.code === '2' && row.is_jieban === true && row.status === 'NORMAL') {
        // 分公司经理申请转为正式材料通知单：status='APPLY'
        msg = '确定申请转为正式材料通知单吗？'
        params.op = 'APPLY'
      } else if (this.usertype.code === '4' && row.status === 'APPLY') {
        // 物资审核：status='WUZISHENHE'
        params.op = 'WUZISHENHE'
      } else if (this.usertype.code === '7' && row.status === 'WUZISHENHE') {
        // 财务审核：status='CAIWUSHENHE'
        params.op = 'CAIWUSHENHE'
      } else if (this.usertype.code === '6' && row.status === 'CAIWUSHENHE') {
        // 分公司经理确认转为正式材料通知单：点击按钮后提示”该借板材料通知单将删除，请到正式材料通知单中查看相应的材料通知单“，
        // 将当前借板材料通知单的合同编号contract_id去掉后边的"-JIEBAN"，得到新合同编号newcontract_id
        // 根据新合同编号newcontract_id在Contracts表中查找对应的合同记录id:new_id
        // 将当前借板材料通知单的合同contract字段改为new_id，is_jieban改为0，status改为FORMAL
        params.op = 'FORMAL'
        msg = '确定转为正式材料通知单吗？该借板材料通知单将被删除，请到正式材料通知单中查看相应的材料通知单!'
      } else return false
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.get('/convertToFormalTongzhidan/', { params: params }).then(res => {
            console.log('isok......................:', res.data)
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getCailiaotongzhidanByID()
            } else {
              this.$message({
                type: 'info',
                message: '操作失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作！'
          })
        })
    },

    /**
     * 材料通知单作废按钮的状态变化：
     * 初始状态:del_status='NORMAL' ，按钮文本：'申请作废' ，分公司经理可操作（其他用户disabled）
     * 1. 分公司经理申请作废，del_status='APPLY'，按钮文本：'等待物资作废审核'，物资可操作（其他用户disabled）
     * 2. 物资审核，del_status='WUZISHENHE'，按钮文本：‘等待财务作废审核'，财务可操作（其他用户disabled）
     * 3. 财务审核：del_status='CAIWUSHENHE',按钮文本：'确认作废'，分公司经理可操作（其他用户disabled）
     * 4. 分公司经理确认作废：点击按钮后提示”该材料通知单将删除，确定要删除吗？“，del_status=‘DEL'
     */
    zuofeiCailiaotongzhidan(row) {
      let params = {
        id: row.id,
        op: ''
      }
      let msg = '确定通过作废审核吗？'
      console.log('当前材料通知单:', row)
      if (this.usertype.code === '2' && row.del_status === 'NORMAL') {
        // 分公司经理申请作废材料通知单：del_status='APPLY'
        params.op = 'APPLY'
      } else if (this.usertype.code === '4' && row.del_status === 'APPLY') {
        // 物资审核：del_status='WUZISHENHE'
        params.op = 'WUZISHENHE'
      } else if (this.usertype.code === '7' && row.del_status === 'WUZISHENHE') {
        // 财务审核：del_status='CAIWUSHENHE'
        params.op = 'CAIWUSHENHE'
      } else if (this.usertype.code === '2' && row.del_status === 'CAIWUSHENHE') {
        // 分公司经理确认作废材料通知单：点击按钮后提示”该材料通知单将删除，确定要删除吗？“，
        // 将当前借板材料通知单的del_status字段改为'DEL'
        params.op = 'DEL'
        msg = '该材料通知单将删除，确定要删除吗？!'
      } else return false
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.get('/zuofeiTongzhidan/', { params: params }).then(res => {
            console.log('isok......................:', res.data)
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getCailiaotongzhidanByID()
            } else {
              this.$message({
                type: 'info',
                message: '操作失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作！'
          })
        })
    },
    /**
     * 申请修改材料通知单
     * 初始状态:modify_status='NORMAL' ，按钮文本：'申请修改' ，分公司经理可操作（其他用户disabled）
     * 1. modify_status='APPLY'，按钮文本：'等待物资批准修改'，物资可操作（其他用户disabled）
     * 2. modify_status='WUZISHENHE'，按钮文本：‘修改'，分公司经理可操作（其他用户disabled）
     * 3. modify_status=‘FORMAL'
     */
    ApplyEditCailiaotongzhidan(row) {
      let params = {
        id: row.id,
        op: ''
      }
      let msg = '确定通过审核吗？'
      console.log('当前材料通知单:', row)
      if (this.usertype.code === '2' && (row.modify_status === 'NORMAL' || row.modify_status === 'MODIFIED')) {
        // 分公司经理申请修改材料通知单：modify_status='APPLY'
        msg = '确定申请修改材料通知单吗？'
        params.op = 'APPLY'
        console.log('--------------------------APPLY')
      } else if (this.usertype.code === '4' && row.modify_status === 'APPLY') {
        // 物资审核：modify_status='WUZISHENHE'
        console.log('--------------------------WUZISHENHE')
        params.op = 'WUZISHENHE'
      } else if (this.usertype.code === '2' && row.modify_status === 'WUZISHENHE') {
        console.log('--------------------------edit')
        this.editCailiaotongzhidan(row)
        return false
      } else return false
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.get('/applyeditTongzhidan/', { params: params }).then(res => {
            console.log('isok......................:', res.data)
            if (res.data.code === '1000') {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getCailiaotongzhidanByID()
            } else {
              this.$message({
                type: 'info',
                message: '操作失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作！'
          })
        })
    },
    //显示材料通知单修改记录
    viewEditRecord(row) {
      console.log('材料通知单修改记录：', row)
      const api = '/getOldtongzhidanbyID/'
      this.$axios
        .get(api, {
          params: {
            id: row.id
          }
        })
        .then(res => {
          console.log('res.data', res.data)
          this.cailiaotongzhidanEditViewTableData = res.data.data.map(item => {
            item.edit_time = utils.formatDateTime(item.edit_time, 'yyyy-MM-dd hh:mm')
            return item
          })
          row.edit_time = '修改后'
          this.cailiaotongzhidanEditViewTableData.push(row)
        })
        .catch(function(error) {
          console.log(error)
        })

      this.cailiaotongzhidanEditRecordDlg_isShow = true
    }
  },
  created() {
    this.getHeyueInfo()
    this.getContractDetail()
    this.getCailiaotongzhidanByID()
    this.getChubanqingkuangByID()
  }
}
</script>

<style scoped>
.center {
  background-color: #ffffff;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  margin-bottom: 5px;
}
.title {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-size: 16px;
  color: white;
}
.header-div {
  display: flex;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  justify-content: space-between;
  background-color: rgb(116, 168, 190);
}
.button-div {
  padding-left: 20px;
}
</style>
